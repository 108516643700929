<template>
  <div>
    <Nav></Nav>
    <div class="img">
      <div class="imgbox">
        <p class="P1">新闻中心</p>
      </div>
    </div>

    <!-- 公司新闻 -->
    <div class="daohan">
      <div class="box">公司新闻 > 新闻中心</div>
    </div>
    <!-- <div class="gsnews">
            <div class="gsnewmav">
                <div style="font-size: 14px;">公司新闻</div>
                <div style="font-size: 14px;color: red;"  @click="gonewsDetails">查看更多>></div>
            </div>
            <div class="gsnewsBox">
                <div class="gsnewsBox1"><img src="../assets/华南官网2.0素材/新闻中心/矩形 758.png" style="width: 100%;height: 100%;"
                        alt=""></div>
                <div class="gsnewsBox2">
                    <p class="Box2P1" style="line-height: 25px;">华南机械4000T/H砂石骨料项目建设现场</p>
                    <p class="Box2P2" style="line-height: 35px;">日期：2022-12-28 09:36:13</p>
                    <p class="Box2P3">项目建设无小事，小到基础建设大到投产运营，都是影响总体产能及稳定生产的决定性因素。
                        那些隐藏在视觉之下的基础、焊接、安装角度等细节工艺，疏忽掉其中的一个细微的步骤，都会对生产线的质量大打折扣； 从源头拒绝华而不实的“面子工程”，透过“魔鬼细节”看真相，打造内外兼修的大型生产线样板！
                    </p>
                </div>
            </div>
            <div class="gsnewsul">
                <div class="item"  @click="gonewsDetails"><span>· 华南机械4000T/H砂石骨料项目建设现场</span><span class="span"
                        style="float: right;">2022-12-28</span>
                </div>
                <div class="item">· 让传统破碎生产线跟上"环保、智能"新步伐<span class="span" style="float: right;">2022-12-19</span></div>
                <div class="item">· 解密║机制砂生产线的升级改造方案<span class="span" style="float: right;">2022-12-02</span></div>
                <div class="item">· 不为困难找借口，只为实干想办法<span class="span" style="float: right;">2022-11 -24</span></div>

            </div>

            <div class="gszxun">
                <div style="font-size: 14px;">行业资讯</div>
                <div style="font-size: 14px; color: red;" @click="gocase">了解更多>></div>
            </div>
            <div class="gsnewsul" style="border-bottom: 1px solid #cccc;">
                <div class="item"><span>· 华南机械4000T/H砂石骨料项目建设现场</span><span class="span"
                        style="float: right;">2022-12-28</span>
                </div>
                <div class="item">· 让传统破碎生产线跟上"环保、智能"新步伐<span class="span" style="float: right;">2022-12-28</span></div>
                <div class="item">· 解密║机制砂生产线的升级改造方案<span class="span" style="float: right;">2022-12-02</span></div>
                <div class="item">· 不为困难找借口，只为实干想办法<span class="span" style="float: right;">2022-11-24</span></div>
            </div>
        </div> -->

    <!-- <callme class="callme"></callme> -->

    <div class="news_list">
      <div
        class="news_item"
        v-for="(item, index) in newsList"
        :key="index"
        @click="
          $router.push({
            name: 'newDetails',
            params: { content: item.content },
          })
        "
      >
        <div class="news_box">
          <img :src="item.img" alt="" />
          <div class="news_text">
            <div>{{ item.title }}</div>
            <div>
              {{ item.text }}
              <div :style="{ 'margin-top': '2px' }">
                {{ item.textTwo }}
              </div>
            </div>
          </div>
        </div>

        <div class="news_time">
          <div class="time">
            <div>{{ item.day }}</div>
            <div>{{ item.time }}</div>
          </div>
        </div>
      </div>
    </div>
    <FTrecord></FTrecord>
  </div>
</template>

<script>
import Nav from "../components/nav.vue";
import FTrecord from "../components/FTrecord.vue";
import router from "@/router";
import NewsInquiry from "../components/NewsInquiry.vue";
import callme from "../components/callme.vue";
export default {
  components: {
    Nav: Nav,
    FTrecord: FTrecord,

    // 新闻资讯
    NewsInquiry: NewsInquiry,
    callme: callme,
  },
  data() {
    return {
      FTrecord: "true",
      FTrecord_min: "true",
      img: "",
      newsList: [
        {
          img: require("../assets/华南官网2.0素材/新闻中心/图片6.jpg"),
          title: "喜报|华南机械最新荣获实用新型专利证书",
          text: "实用新型名称：具有抑尘功能的砂石骨料多出口自动转换结构;",
          textTwo: "专利权人：华南机械制造有限公司",
          day: "06",
          time: "2024-08",
          content: {
            newsDetalisTitle:
              "实用新型名称：具有抑尘功能的砂石骨料多出口自动转换结构",
            newsDetalis: [
              {
                type: "textName",
                content: "【技术背景】",
              },
              {
                type: "text",
                content:
                  "砂石骨料是建筑和道路工程中广泛使用的一种重要材料。它主要由石子、沙子、碎石等组成，为了提高砂石骨料的运输、存储效率和使用效果，通常需要将砂石骨料进行分类存储，根据不同的颗粒级配要求，将砂石骨料按照不同粒径分别储存。",
              },

              {
                type: "text",
                content:
                  "目前，现有的砂石骨料分类存储装置在进行运输砂石骨料时，很容易在工作场所内扬起大量粉尘，从而对环境造成污染以及给人体带来不适。",
              },
              {
                type: "imgTwo",
                content: [
                  require("../assets/华南官网2.0素材/新闻中心/图片4.png"),
                  require("../assets/华南官网2.0素材/新闻中心/图片5.png"),
                ],
              },
              {
                type: "textName",
                content: "【实用新型内容】",
              },

              {
                type: "text",
                content:
                  "本实用新型的目的是为了解决现有技术中砂石骨料运输时容易扬起大量粉尘，会对环境造成污染以及给人体带来不适的问题，而提出的一种具有抑尘功能的砂石骨料多出口自动转换结构。",
              },
              {
                type: "text",
                content:
                  "该具有抑尘功能的砂石骨料多出口自动转换结构通过活塞盒、活塞板、复位弹簧、雾化喷头、弧形板以及第一磁板的设置，利用第一磁板与活塞板间的磁性相斥作用，持续性的向集尘管内喷洒水雾，以此与扫落进来的灰尘相结合，有效将灰尘进行吸收，提高了该装置的抑尘效果，确保了工作场所内环境的整洁性与舒适度。",
              },
              {
                type: "img",
                content: require("../assets/华南官网2.0素材/新闻中心/图片3.jpg"),
              },
              {
                type: "img",
                content: require("../assets/华南官网2.0素材/新闻中心/图片2.png"),
              },
              {
                type: "textName",
                content: "【企业荣誉】",
              },

              {
                type: "text",
                content:
                  "2009年企业创办至今获得设备生产技术专利和生产线技术专利证书100多项。华南机械先后成为中国砂石协会理事单位和绿色矿山产业联盟的常务理事单位，绿色矿山产业联盟技术委员会委员，并参与中国砂石行业规范（国家标准号:DZ/T-0316-2018）的制定，于2018年9月在广东股权交易中心挂牌（股权代码892335)。2018-2020年连续三年获得“广东省守合同重信用企业”荣誉，2020年通过国家高新技术企业认证，2023年通过ISO9000质量管理体系认证，2024年华南机械通过“专精特新企业认证”。",
              },
              {
                type: "img",
                content: require("../assets/华南官网2.0素材/新闻中心/图片1.jpg"),
              },
            ],
          },
        },
        {
          img: require("../assets/华南官网2.0素材/新闻中心/矩形 758.png"),
          title: "华南机械4000T/H砂石骨料项目建设现场",
          text: "项目建设无小事，小到基础建设大到投产运营，都是影响总体产能及稳定生产的决定性因素。那些隐藏在视觉之下的基础、焊接、安装角度等细节工艺，疏忽掉其中的一个细微的步骤，都会对生产线的质量大打折扣；从源头拒绝华而不实的“面子工程”，透过“魔鬼”看真相，打造内外兼修的大型生产线样板！",
          day: 28,
          time: "2022-12",
          content: {
            newsDetalisTitle: "华南机械4000T/H砂石骨料项目建设现场",
            newsDetalis: [
              {
                type: "textTitle",
                content:
                  "项目建设无小事，小到基础建设大到投产运营，都是影响总体产能及稳定生产的决定性因素。 那些隐藏在视觉之下的基础、焊接、安装角度等细节工艺，疏忽掉其中的一个细微的步骤，都会对生产线的质量大打折扣； 从源头拒绝华而不实的“面子工程”，透过“魔鬼”看真相，打造内外兼修的大型生产线样板！",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915551053472.jpg",
              },
              {
                type: "textTitle",
                content:
                  "看似寻常最奇崛，成如容易却艰辛。没有豪言壮语，他们是一群默默无闻的华南队伍，平凡而不平庸；梦想在前，路在脚下，拥有铁军之骄的机械之师，正用创新走出新路，用奉献成就绿色坦途的梦想!",
              },
              {
                type: "textName",
                content: "(筛分区围蔽)",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915569053103.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915605027264.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915622013124.jpg",
              },

              {
                type: "text",
                content:
                  "化身工地“蒙面焊匠”，利用专业的技术，遵照施工标准对每一个环节进行认真地施工。 着重安全施工的同时，用专业和匠心对待每一个环节与品质； 严守文明施工规范指南，落实日常作业安全保障。",
              },
              {
                type: "textName",
                content: "(半成品料仓)",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915639053595.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915661069334.jpg",
              },
              {
                type: "text",
                content:
                  "事无巨细,事必躬亲。 料仓的流型选择、进料口尺寸、卸料口尺寸、容量的大小、下料倾角、高度、给料机的选用、周边的辅助设计等都是重中之重，都逃不过巡检人员犀利的双眼； 稳扎硬性指标细节，严把工程质量，做到精益求精！",
              },

              {
                type: "textName",
                content: "(存储料罐 )",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915679075259.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915698089711.jpg",
              },
              {
                type: "text",
                content:
                  "施工现场按要求进行布置，水、电、土建基础等，项目组含各类施工人员且具备相应资质，储罐用的钢板、型材和附件符合设计要求。在建设过程中对储罐的预制、储罐的起升方式、组对安装过程以及储罐的焊接等工作进行全时监督。",
              },
              {
                type: "textName",
                content: "（输送架）",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915726073467.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915755053560.jpg",
              },
              {
                type: "text",
                content:
                  "①做好安全设计、风险评估，针对短路、防腐等情况拟定预防方案；②根据总设计方案做密闭罩设计、胶带回程带灰清扫等防尘设计方案；③针对日常的工作量加装断带保护方案。独特而稳定的输送带设计确保让物料输送畅通无阻",
              },
              {
                type: "textName",
                content: "基础建设 规划与交底",
              },
              {
                type: "text",
                content:
                  "有一个稳定和团结的队伍，从而保障在项目推进的情况下能按时完成设计、规划、安装、投产的主要工作；随时了解和完成客户的需求，为项目的总体进度提供保障。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915787085448.jpg",
              },
              {
                type: "text",
                content: "实时交流、反复论证",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915810046288.jpg",
              },
              {
                type: "text",
                content:
                  "围绕着工地施工准则，为品质重重把关、 科学管理、精细施工 对工艺与要点规范落实，精抓细节，对工程质量进行无盲点巡查与把控。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915840024614.png",
              },

              {
                type: "text",
                content:
                  "精细化的施工管理，由表及里！管理组对现场的施工计划、施工进度、每日的安全教育及项目总结会议等工作进行细化和落实，让施工质量更加规范安全化、管理更加制度化；同时强化标准执行，打造标杆工地！",
              },
            ],
          },
        },
        {
          img: require("../assets/华南官网2.0素材/新闻中心/2.jpg"),
          title: "华南机械4000T/H砂石骨料项目建设现场",
          text: "我国砂石行业呈现环保化、大型化、智能化等发展趋势，高质量发展的诉求对砂石生产设备的环保、节能、高效、大产能、颗粒级配等方面提出了更高的要求。",
          day: 19,
          time: "2022-12",
          content: {
            newsDetalisTitle: '让传统破碎生产线跟上"环保、智能"新步伐',
            newsDetalis: [
              {
                type: "textTitle",
                content:
                  "我国砂石行业呈现环保化、大型化、智能化等发展趋势，高质量发展的诉求对砂石生产设备的环保、节能、高效、大产能、颗粒级配等方面提出了更高的要求。",
              },
              {
                type: "text",
                content:
                  "为使项目达到预期生产效果，实现既定产能，华南机械对破碎生产线提供全面的解决方案，达到自动化程度高、破碎率高，节能，产量大，生产出的石子粒度均匀、粒形好，适合公路桥梁等各种大中小工程项目建设的需求。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856851778000319.png",
              },

              {
                type: "textName",
                content: "环保方案",
              },
              {
                type: "text",
                content:
                  "物料破碎时，容易产生大量粉尘、噪音，因此破碎生产线的建设，必须达到环保要求，符合环保建设标准。华南机械通过封闭式设计，配置防尘罩、除尘器、喷淋设备，多管齐下，提升了综合生产线的环保性能，粉尘捕获率高达99%，更有效降低了噪音，打造了内外兼顾的高品质破碎砂石生产线。",
              },
              {
                type: "text",
                content:
                  "破碎生产线通过搭建厂房，有效阻断粉尘向空气中排放，也可降低噪音污染，对运输车辆密封覆盖以及对厂区安装喷雾除尘设备等进行有效控尘。",
              },
              {
                type: "text",
                content:
                  "针对破碎作业车间外溢的粉尘，可以配置气箱式脉冲袋收尘器对粉尘进行回收再利用；可在给料前端、筛料排料口配置雾化水喷淋，降低扬尘，同时在输送带输送环境配置密封罩，成品料建设料仓等。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856852711040817.jpg",
              },
              {
                type: "imgName",
                content: "(生产区围蔽)",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856852961041297.png",
              },
              {
                type: "imgName",
                content: "（密封式输送带)",
              },
              {
                type: "textName",
                content: "破碎设备的配置",
              },

              {
                type: "text",
                content:
                  "破碎生产线中，如石料规格尺寸较大，可选用华南机械颚式破碎机作为一级破碎。如遇对石料规格尺寸要求较严，需要由一定级配的石料组成时，则需配套使用破碎设备，如颚破、反击破或圆锥破组成的联合破碎产线。",
              },

              {
                type: "text",
                content:
                  "PE颚式破碎机采用挤压破碎工作原理，优化运动参数，破碎腔深、没有死区，且破碎比更大，不会发生堵料，有楔块调整排料口，减小了工人劳动强度和停机时间，粒度均匀、有助于二段破碎处理，同时电机座和主机一体化结构，使安装更加简便、节约占用空间。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856853255026111.jpg",
              },
              {
                type: "imgName",
                content: "（颚式破碎机）",
              },

              {
                type: "text",
                content:
                  "PFQ重型反击式破碎机适用于如玄武岩、安山岩、河卵石、花岗岩等物料的破碎。采用新概念破碎技术，能满足不同物料规格破碎，完全满足“多碎少磨”新工艺要求，反击板与板锤间隙能方便调节，有效控制出料粒度，同时新型高耐磨材料大量使用，降低磨损件磨耗，延长更换周期、节约成本。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856853424041956.jpg",
              },
              {
                type: "imgName",
                content: "(反击式破碎机)",
              },
              {
                type: "text",
                content:
                  "（PSC/PYB）圆锥破碎机更适用于较硬物料，如玄武岩、鹅卵石等，采用层压破碎原理，轧臼壁向破碎壁运动挤压物料，很大程度上减少了针片状物料，液压马达调整排料口调整操作方便，而且易损件耐磨性强、解决后期运行成本过高问题，增长使用寿命。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856853570012886.jpg",
              },
              {
                type: "imgName",
                content: "（左1-PYB圆锥破碎机，右2-PSC圆锥破碎机）",
              },
              {
                type: "text",
                content:
                  "（PSC/HN制砂机系列，进料粒径≤50mm，采用“石打石”和“石打铁”工作模式，又有深腔叶轮的效率提高，处理得到0-5mm石子及沙子，所以成品粒形好、没有细纹，无粗细颗粒离析现象，细度模数可调，适合人工制砂和石子整形。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856853758087966.png",
              },
              {
                type: "imgName",
                content: "(冲击式破碎机)",
              },
              {
                type: "text",
                content:
                  "破碎生产线一般拥有长期料源，而性能稳定、高产能的设备就成为了大家重点考察因素。华南机械破碎设备组成的生产线极具稳定，处理能力强，产量高，能够处理各种复杂的建筑垃圾、骨料，应用范围极广。",
              },
              {
                type: "textName",
                content: "智慧智能  可视可控",
              },
              {
                type: "text",
                content:
                  "“减少用人数量，推动智能化。”是当下砂石生产线建设的新趋势。华南机械研发的‘智慧矿山’，解决了传统控制系统的诸多局限。在破碎生产线案例中不仅减少了用人数量，提高设备运转率，更提高了无故障连续运转时间，有效推动了传统企业实现数字化、智能化的转型。",
              },

              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856854091013631.jpg",
              },
              {
                type: "imgName",
                content: "（项目数据中心）",
              },
              {
                type: "textTitle",
                content:
                  "紧跟‘建设绿色智慧矿山’号召，华南机械砂石骨料项目纷纷建成投产，助力砂石骨料综合环保利用迈入规模化、集约化、智能化新阶段，成为行业发展、产业链延伸、改善生态环境角色中重要的一分子!",
              },
            ],
          },
        },
        {
          img: require("../assets/华南官网2.0素材/新闻中心/3.jpg"),
          title: "解密║机制砂生产线的升级改造方案",
          text: "对于机制砂生产线的建设与升级，我们有着成熟完整的 勘测选址、规划设计、设备安装、试机投产、售后无忧'的方案服务流程。",
          day: "02",
          time: "2022-12",
          content: {
            newsDetalisTitle: "解密║机制砂生产线的升级改造方案",
            newsDetalis: [
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856752847037492.png",
              },
              {
                type: "text",
                content:
                  "对于机制砂生产线的建设与升级，我们有着完整的‘勘测选址、规划设计、设备安装、试机投产、售后无忧的方案服务流程。",
              },
              {
                type: "textTitle",
                content: "华南机械助力贵州省贵定县200T/H机制砂生产线升级改造",
              },
              {
                type: "textName",
                content: "01",
              },
              {
                type: "textTitle",
                content: "勘测选址",
              },
              {
                type: "text",
                content:
                  "此生产线坐落于喀斯特地貌的贵州省境内，由于地形的特殊原因，原有的单一破碎生产线产能小、效益低。我们给到客户一条绿色环保洗砂制砂生产线的升级方案，在原有的破碎生产线的基础上，解决产能和收益上的负增长问题。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856753087002305.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856753289047939.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856753499071002.jpg",
              },
              {
                type: "textName",
                content: "02",
              },
              {
                type: "textTitle",
                content: "规划设计",
              },
              {
                type: "text",
                content:
                  "素有‘地无三尺平’的大山里，需要在有限的空间创造预期的产能。扎营常驻与现场人员保持着密切交流，对场地的布局、产线的配置、前期的基建等现场工作进行可行性方案论证，第一时间给出合理且高效的方案。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856753725097613.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856753905036072.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856754104085630.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856754232060588.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856754459035843.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856754589045865.jpg",
              },
              {
                type: "textName",
                content: "03",
              },
              {
                type: "textTitle",
                content: "设备安装",
              },
              {
                type: "text",
                content:
                  "结合原有的破碎生产线，再加一条制砂洗砂以及污水处理全套的产线，由制砂机到振筛筛分再到水洗。在考虑到后续的产线延伸的方案下，在制砂机旁预留一条破碎的产线可以用于单独锤破。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856754711097237.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856754810050524.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856754913037667.jpg",
              },
              {
                type: "textName",
                content: "04",
              },
              {
                type: "textTitle",
                content: "试机投产",
              },
              {
                type: "text",
                content:
                  "在产线投产过程中，由于产线当时的机修技术和管理各方面的欠缺，导致产线经常出现故障。我们针对此情况立即安排工程师驻场对操作人员指导和培训，我们的电气工程师也对整个产线的电路进行巡查和提供改进方案。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856755007004539.jpg",
              },
              {
                type: "textName",
                content: "05",
              },
              {
                type: "textTitle",
                content: "售后无忧",
              },
              {
                type: "text",
                content:
                  "充足的配件、全天候的在线客服、专业的售后团队及产线方案解决专家，为您的设备和产能保驾护航！",
              },
            ],
          },
        },
      ],
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    gonewsDetails() {
      router.push(
        "./newDetails",
        () => {},
        () => {}
      );
    },
    gocase() {
      router.push("./case");
    },
  },
};
</script>

<style lang="less" scoped>
.news_list {
  width: 60%;
  margin: 0 auto;
  background-color: #fff;
  padding-top: 50px;
  padding-bottom: 80px;

  .news_item {
    display: flex;
    justify-content: space-between;

    cursor: pointer;
    padding-bottom: 20px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 20px;
    .news_box {
      display: flex;
      > img:first-child {
        width: 360px;
        margin-right: 60px;
      }
    }

    .news_text {
      margin-top: 40px;
      // margin-left: 60px;
      margin-right: 80px;
      float: left;

      > div:first-child {
        font-size: 16px;
        font-weight: 800;
      }

      > div:last-child {
        margin-top: 30px;
        font-size: 14px;
        color: #999999;
      }
    }

    .news_time {
      display: flex;
      align-items: center;
      border-left: 1px solid #e9e9e9;
      padding-left: 46px;

      .time {
        > div:first-child {
          font-size: 36px;
          color: #999999;
          font-weight: 600;
          margin-bottom: 20px;
        }

        > div:last-child {
          font-size: 14px;
          color: #999999;
          white-space: nowrap;
        }
      }
    }
  }
}

.img {
  width: 100vw;
  height: 260px;
  background-image: url(../assets/华南官网2.0素材/新闻中心/矩形\ 3.png);
  background-size: 100% 100%;
  -webkit-animation: mov 5s infinite;
}

@-webkit-keyframes mov {
  from {
    transform: translateY(50px);
  }

  to {
    transform: translateY(0px);
  }
}

.imgbox {
  width: 60vw;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.P1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 30px;
  font-size: 24px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 2.4px;
  text-align: center;
}

.daohan {
  width: 100vw;
  height: 50px;
  background: #f4f4f4;
}

.box {
  width: 60%;
  height: 100%;
  margin: 0 auto;
  line-height: 50px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  font-size: 14px;
}

/*  */
.gsnews {
  width: 60vw;
  margin: 0 auto;
  /* background-color: red; */
  margin-top: 50px;
  /* background-color: rgb(141, 214, 216); */
}

.gsnewmav {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #cccc;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
}

.gsnewsBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* background-color: #52658d; */
  padding-bottom: 25px;
  border-bottom: 1px solid #cccc;
}

.gsnewsBox1 {
  width: 30%;
  height: 240px;
  /* background-image: url(); */
}

.gsnewsBox2 {
  width: 65%;
  height: 240px;
}

.Box2P1 {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: justifyLeft;
  color: #333333;
}

.Box2P2 {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: justifyLeft;
  color: #333333;
}

.Box2P3 {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: justifyLeft;
  color: #333333;
}

.gsnewsul {
  width: 100%;
  height: 125px;
  margin-top: 25px;

  display: flex;
  flex-direction: column;
  /* 设置为纵向排列 */
  justify-content: space-between;
  /* 在Y轴上平分剩余空间 */
}

.item {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #121212;
}

.span {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #121212;
}

.gszxun {
  width: 100%;
  height: 40px;
  /* background-color: #80414b; */
  border-bottom: 1px solid #cccc;
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
}

@media all and (max-width: 818px) {
  .img {
    width: 100vw;
    height: 130px;
  }

  .gsnews {
    width: 95%;
  }

  .gsnewsBox {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .gsnewsBox1 {
    width: 100%;
    height: 260px;
    /* background-color: #36b865; */
  }

  .gsnewsBox2 {
    width: 100%;
    height: 100%;
  }

  .Box2P1 {
    font-size: 18px;
    font-weight: 700;
    margin-top: 10px;
  }

  .Box2P2 {
    /* font-size: ; */
  }
}

@media screen and (max-width: 821px) {
  .daohan {
    .box {
      width: 95%;
    }
  }

  .gsnews {
    width: 95dvw;
  }
}

.callme {
  margin-top: 50px;
  margin-bottom: 100px;
}
</style>
