<template>
  <div class="newsDetalis">
    <Nav></Nav>
    <div class="img">
      <div class="imgbox">
        <p class="P1">新闻中心</p>
      </div>
    </div>
    <!-- 公司新闻 -->
    <div class="daohan">
      <div class="box">公司新闻 > 新闻中心</div>
    </div>

    <!-- 新闻详情 -->
    <div class="newsDetalisBox">
      <h1 class="newsDetalisBoxP1">
        {{ $route.params.content.newsDetalisTitle }}
      </h1>
      <p class="newsDetalisBoxP2"></p>
      <p class="newsDetalisBoxP3">
        发布日期：Wed Dec 28 09:36:13 CST 2022 点击次数： 3000
      </p>
      <div
        v-for="(item, index) in $route.params.content.newsDetalis"
        :key="index"
        class="news_Detalis"
      >
        <img
          v-if="item.type === 'img'"
          :src="item.content"
          alt=""
          class="newsDetalis_img"
        />
        <div class="newsDetalis_imgTwo" v-if="item.type === 'imgTwo'">
          <img :src="item.content[0]" alt="" class="newsDetalis_img" />
          <img :src="item.content[1]" alt="" class="newsDetalis_img" />
        </div>

        <div v-if="item.type === 'text'" class="text">{{ item.content }}</div>
        <div v-if="item.type === 'imgName'" class="imgName">
          {{ item.content }}
        </div>
        <div v-if="item.type === 'textName'" class="textName">
          {{ item.content }}
        </div>
        <div v-if="item.type === 'textTitle'" class="textTitle">
          {{ item.content }}
        </div>
      </div>
    </div>

    <!-- 上下篇 -->
    <!-- <div class="ThePreceding">
            <div class="left">上一篇：解密║机制砂生产线的升级改造方案</div>
            <div class="right">下一篇：华南机械祝贺第五届全国绿色矿业发展大会圆满成功</div>
        </div> -->

    <!-- 相关新闻 -->
    <!-- <div class="RelatedNews">
            <p>相关新闻：</p>
            <div class="RelatedNewsBox" v-for="(item, index) in RelatedNews" :key="index">
                <div class="RelatedNewsBoxleft">{{ item.title }}</div>
                <div class="RelatedNewsBoxright">{{ item.time }}</div>
            </div>
        </div> -->

    <FTrecord style="margin-top: 50px"></FTrecord>

    <!-- <div v-html="this.html"></div> -->
    <router-view></router-view>
  </div>
</template>

<script>
import Nav from "../components/nav.vue";
import FTrecord from "../components/FTrecord.vue";
// import router from '@/router';
// import axios from 'axios';
import NewsInquiry from "../components/NewsInquiry.vue";
import callme from "../components/callme.vue";
export default {
  components: {
    Nav: Nav,
    FTrecord: FTrecord,
    NewsInquiry: NewsInquiry,
    callme: callme,
  },
  data() {
    return {
      RelatedNews: [
        { title: "· 华南机械4000T/H砂石骨料项目建设现场", time: "2022-12-28" },
        { title: "· 华南机械4000T/H砂石骨料项目建设现场", time: "2022-12-28" },
        { title: "· 华南机械4000T/H砂石骨料项目建设现场", time: "2022-12-28" },
        { title: "· 华南机械4000T/H砂石骨料项目建设现场", time: "2022-12-28" },
      ],
    };
  },
  mounted() {
    //    axios.get('https://www.southchinamachine.com/newsPage/selectNews?ID=1',{
    //    }).then(res =>{
    //         // console.log(res.data);
    //         this.decapitating(res)
    //         this.withdrawCN(res)
    //    })
  },
  methods: {
    // 去除结构头尾
    decapitating(res) {
      this.html = res.data;
      var startIndex = this.html.indexOf("<body>");
      this.html = this.html.substring(startIndex);

      var endIndex = this.html.indexOf("</body>");
      this.html = this.html.substring(0, endIndex + "</body>".length - 1);
      console.log(this.html);
    },
    withdrawCN(res) {
      this.html = res.data;
      const chineseRegex = /[\u4e00-\u9fa5]+/g;
      const matches = this.html.match(chineseRegex);
      console.log(matches);
    },
  },
};
</script>

<style scoped lang="less">
.news_Detalis {
  font-size: 16px;
  .imgName {
    // text-align: center;
    margin-top: 6px;
  }
  .textName {
    color: red;
    font-weight: 600;
    font-size: 20px;
    margin-top: 70px;
  }
  .text {
    margin-top: 30px;
  }

  .newsDetalis_imgTwo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .newsDetalis_img {
      width: 49%;
      height: 320px;
    }
  }
  .newsDetalis_img {
    margin-top: 20px;
    width: 100%;
  }
  .textTitle {
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 18px;
    margin-top: 20px;
  }
}
.newsDetalis {
  -webkit-animation: mov 5s infinite;
}

.img {
  width: 100vw;
  height: 260px;
  background-image: url(../assets/华南官网2.0素材/新闻中心/矩形\ 3.png);
  background-size: 100% 100%;
}

.imgbox {
  width: 60vw;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.P1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 30px;
  font-size: 24px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 2.4px;
  text-align: center;
}

.daohan {
  width: 100vw;
  height: 50px;
  background: #f4f4f4;
}

.box {
  width: 60%;
  height: 100%;
  margin: 0 auto;
  line-height: 50px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  font-size: 14px;
}

/* 新闻详情 */
.newsDetalisBox {
  width: 60vw;
  margin: auto;
  padding-top: 50px;
}

.newsDetalisBoxP1 {
  display: block;
  font-weight: 600;
  text-align: left;
  color: #cf000e;
  margin-bottom: 20px;
}

.newsDetalisBoxP2 {
  display: block;
  width: 100%;
  height: 1px;
  background: #cccccc;
  margin-bottom: 8px;
}

.newsDetalisBoxP3 {
  width: 100%;
  height: 14px;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 14px;
  margin-top: 14px;
}

.newsDetalisBoxP4 {
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: justifyLeft;
  color: #333333;
  margin-top: 8px;
  line-height: 20px;
}

.newsDetalisBox_item1 {
  width: 100%;
  height: 480px;
  padding-top: 50px;
}
.newsDetalisBox_item2 {
  height: 280px;
  /* background-color: red; */
}
.itemImg220 {
  height: 220px;
  /* background-color: red; */
}
.itemImg {
  width: 600px;
  height: 400px;
  margin: auto;
  /* background-color: #56cb90; */
  display: flex;
  justify-content: space-between;
}
.itemImg1left {
  width: 49%;
  height: 100%;
  /* background-color: red; */
}
.itemImg1right {
  width: 49%;
  height: 100%;
}
.right1,
.right2 {
  width: 100%;
  height: 49%;
  /* background-color: yellow; */
}
.right1 {
  margin-bottom: 3%;
}
.itemtext {
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: justifyLeft;
  color: #333333;
  padding-top: 10px;
}

.item1Img {
  width: 600px;
  height: 400px;
  margin: auto;
  background-color: #56cb90;
}

.item1text {
  width: 100%;
  height: 70px;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: justifyLeft;
  color: #333333;
  padding-top: 10px;
}

.item1textP1 {
  font-weight: 700;
  text-align: center;
}

.item1textP2 {
  padding-top: 10px;
}

.ThePreceding {
  width: 60vw;
  height: 25px;
  margin: auto;
  margin-top: 75px;
  border-bottom: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
  display: flex;
  justify-content: space-between;
}

.left {
  width: 50%;
  height: 100%;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: justifyLeft;
  color: #333333;
  line-height: 25px;
  margin-left: 25px;
  border-right: 1px solid #cccccc;
}

.right {
  width: 50%;
  height: 100%;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: justifyLeft;
  color: #333333;
  line-height: 25px;
  margin-left: 25px;
}

/* 相关新闻 */
.RelatedNews {
  width: 60vw;
  height: 156px;
  margin: auto;
  margin-top: 50px;
}

.RelatedNewsBox {
  width: 100%;
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
}

.RelatedNewsBoxleft,
.RelatedNewsBoxright {
  font-size: 14px;
}

@-webkit-keyframes mov {
}

@media all and (max-width: 818px) {
  .newsDetalis {
    width: 100%;
  }

  .img {
    height: 130px;
  }

  .itemImg,
  .item1Img {
    width: 100%;
  }

  .box {
    margin-left: 10px;
  }

  .newsDetalisBox {
    width: 95%;
    padding-top: 25px;
  }

  .newsDetalisBoxP1 {
    font-size: 14px;
  }

  .newsDetalisBoxP3,
  .newsDetalisBoxP4 {
    font-size: 12px;
  }
  .newsDetalisBoxP4 {
    margin-bottom: 50px;
  }
  .newsDetalisBox_item1 {
    padding-top: 25px;
    height: 300px;
  }
  .item1Img {
    width: 100%;
    height: 200px;
  }
  .itemImg {
    width: 100%;
    height: 200px;
  }
  .item1textP2 {
    font-size: 12px;
  }

  .ThePreceding {
    display: none;
  }

  .RelatedNews {
    width: 95%;
  }
}
</style>
